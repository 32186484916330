/* App.css */ 

.container {
    font-family: "Times New Roman", Times, serif;
    font-size: 16px;
    line-height: 1.6;
    width: 60%; /* Adjust the width to center the content to the left */
    margin: 20px auto; /* Centers the container horizontally */
    padding: 20px;
    border: 3px solid black; /* Draws a black box around the content */
    /* background-color: #f9f9f9; /* Optional: Light background for better visibility */
    text-align: left;
  }  
  
  .title {
    text-align: center;
    font-size: 2.5em;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .subtitle {
    text-align: center;
    font-size: 1.2em;
    font-style: italic;
    color: #555;
    margin-top: 40px;
  }
  
  .section {
    margin-bottom: 20px;
  }
  
  .highlight {
    font-weight: bold;
  }
  